.App {
  text-align: center;
}

/* mobile */

@media (max-width: 768px) {
  .feature-item img {
    width: 100%; 
  }
}

@media (max-width: 768px) {
  .endpoints {
    flex-direction: column; 
  }
}

@media (max-width: 768px) {
  .homepage img {
    max-width: 300px;
    max-height: 200px; 
  }
}

@media (max-width: 768px) {
  .homepage {
    position: relative;
    background-image: url('./assets/Wallpaper/IMG_1266.JPG');
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat; 
  }
}

/* repeated the backgorund but didnt look very good */
/* @media (max-width: 768px) {
  body {
    position: relative;
    background-image: url('./assets/Wallpaper/IMG_1266.JPG');
    background-size: contain; 
    background-position: center; 
    background-repeat: repeat; 
  }
} */


.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgba(188, 184, 184, 0.689);
  margin: 10px;
}



.feature-item h2 {
  color: black;
}

.feature-item a {
  margin-bottom: 10px;
}

.feature-item img {
  width: 75%;
}


.endpoints {
  display: flex;
  justify-content: center; 
  align-items: center;
  text-align: center;
}


.contact {
  background-color: rgba(188, 184, 184, 0.689);
  margin-right: 20px;
  margin-left: 20px;
  color: black;
  position: relative;
}


.homepage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/Wallpaper/IMG_1266.JPG');
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat; 
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Courier New';
}


/* Arial
Helvetica
Times New Roman
Courier New
Verdana
Georgia
Trebuchet MS
Calibri
Arial Black
Impact */

.all-products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 100%
} 


@media (max-width: 500px) {
  .all-products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust the number of columns as needed */
    gap: 10px; /* Adjust the gap between grid items as needed */
    width: 100%
  }
}




.product-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(188, 184, 184, 0.689);
}

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-header h2 {
  color: black;
  margin-right: -75px;
  background-color: rgba(165, 42, 42, 0.601);
}

.product-header h1 {
  color:black;
  margin: 0 auto;
}

.contact {
  background-color: rgba(188, 184, 184, 0.689);
  margin-right: 60%;
  margin-left: 10%;
  color: black;
  position: relative;
}

@media (max-width: 500px) {
  .contact {
    background-color: rgba(188, 184, 184, 0.689);
    margin-right: 20px;
    margin-left: 20px;
    color: black;
    position: relative;
  }
}